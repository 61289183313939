<template>
    <div class="alert-report__associate">
        <img 
            src="@/assets/images/user-profile.svg" 
            class="alert-report__associate-image" 
            width="70"
            height="70" 
            alt="" 
            v-if="!associateInfo.associateImage"
        >

        <img 
            :src="`${associateInfo.associateImage}`" 
            class="alert-report__associate-image" 
            width="70" 
            height="70" 
            v-else
        >

        <div class="alert-report__associate-info">
            <h3 class="alert-report__associate-name">
                {{ associateInfo.associateName }}
            </h3>

            <div class="alert-report__associate-fields">
                <div class="alert-report__associate-field" v-if="associateInfo.associateEmail">
                    <img src="@/assets/icons/vuesax/linear/sms-notification.svg" width="15" height="15" alt="">
                    {{ associateInfo.associateEmail }}
                </div>

                <div class="alert-report__associate-field">
                    <img src="@/assets/icons/vuesax/linear/call-calling.svg" width="15" height="15" alt="">
                    {{ associateInfo.associatePhone }}
                </div>

                <div class="alert-report__associate-field">
                    <img src="@/assets/icons/vuesax/linear/notification.svg" width="15" height="15" alt="">

                    Status:

                    <span class="alert-report__status-completed" v-if="associateInfo.alertStatusId === 3">
                        {{ associateInfo.alertStatusName }}
                    </span>

                    <span class="alert-report__status-pending" v-if="associateInfo.alertStatusId === 6">
                        {{ associateInfo.alertStatusName }}
                    </span>
                </div>
            </div>

            <a href="#" class="alert-report__evidences-link" @click.prevent="showEvidences">
                Evidence: {{ evidences.length }}
                <img src="@/assets/icons/vuesax/linear/arrow-right.svg" width="15" height="15" alt="">
            </a>
        </div>
    </div>

    <Evidences :evidences="evidences" :show-evidences="showEvidencesList" />
</template>

<script setup>
import { ref } from 'vue'
import Evidences from './Evidences.vue'

const props = defineProps(['associateInfo', 'evidences'])
const showEvidencesList = ref(false)

function showEvidences() {
    if (!props.evidences.length) showEvidencesList.value = false
    else showEvidencesList.value = !showEvidencesList.value
}
</script>