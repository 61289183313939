import { httpRequests } from '../../../helpers/HttpRequests.js'

export function alertReportServices() {
    const { getRequest, putRequest } = httpRequests()

    function getAlertReportService(alertId) {
        return getRequest(`/web/alerts_logs/report/${alertId}`)
    }

    function setAlertStatusToCompleteService(alertId) {
        return putRequest(`/web/alerts_logs/set_complete/${alertId}`)
    }

    return { getAlertReportService, setAlertStatusToCompleteService }
}