<template>
    <div class="content-loader">
        <img src="@/assets/images/prosafe-new-logo.svg" class="content-loader__image" width="300" alt="">
        <div class="content-loader__text">
            <span class="content-loader__loader"></span>
            <h3 class="content-loader__loader-text">Loading Information...</h3>
        </div>
    </div>
</template>

<script setup>
import { watch } from 'vue'

const props = defineProps(['hiddenLoader'])

watch(() => props.hiddenLoader, (value) => {
    const element = document.querySelector('.content-loader')
    
    if (value) element.classList.add('content-loader__preloader-hide')
})
</script>