<template>
    <div class="alert-report__evidences">
        <table class="alert-report__table">
            <tbody>
                <tr v-for="(evidence, index) in evidences" :key="index">
                    <td>{{ evidence.videoName }}</td>
                    <td>{{ evidence.videoDate }}</td>
                    <td>{{ evidence.videoTime }}</td>
                    <td>
                        <div class="alert-report__evidence-options">
                            <a :href="`${evidence.videoUrl}`" target="_blank">
                                <img src="@/assets/icons/vuesax/linear/play-cricle.svg" width="20" height="20">
                            </a>

                            <a :href="`${evidence.videoUrl}`" download target='_blank'>
                                <img src="@/assets/icons/vuesax/linear/receive-square.svg" width="20" height="20">
                            </a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'

const props = defineProps(['evidences', 'showEvidences'])
const element = ref(null)
const emits = defineEmits(['hiddenEvidences'])

onMounted( () => {
    element.value = document.querySelector('.alert-report__evidences')

    if (!props.showEvidences) element.value.classList.add('alert-report--evidences-hidden')
})

watch(() => props.showEvidences, (value) => {
    if (value) element.value.classList.add('alert-report--evidences-show')
    else element.value.classList.remove('alert-report--evidences-show')
})
</script>