<template>
    <div class="alert-report__close-options" v-if="statusId === 6 && !isError">
        Please provide the reason this alert was triggered:

        <div class="alert-report__buttons-options">
            <button class="button button--danger button--small-padding" @click="showErrorOptions(true)">
                Mark as Error
            </button>

            <RouterLink :to="`/incidents/add/${alertId}`" class="button button--success button--small-padding">
                Create Incident
            </RouterLink>
        </div>
    </div>

    <div class="alert-report__close-options" v-if="statusId === 6 && isError">
        Confirm Mark as Error?

        <div class="alert-report__buttons-options">
            <button class="button button--danger button--small-padding" @click="markAlertError">
                Yes, Mark as Error
            </button>

            <RouterLink :to="`/incidents/add/${alertId}`" class="button button--success button--small-padding">
                Create Incident
            </RouterLink>
        </div>
    </div>
</template>

<script setup>
import { sweetAlerts } from '@/helpers/SweetAlert.js'
import { alertReportServices } from '../Services/AlertReportServices'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { usePanicAlertStore } from '@/stores/PanicAlertStore.js'

const props = defineProps(['statusId', 'alertId'])
const isError = ref(false)
const router = useRouter()
const { setAlertStatusToCompleteService } = alertReportServices()
const { sweetSuccess } = sweetAlerts()
const { getTotalPendingAlerts } = usePanicAlertStore()

function showErrorOptions(value) {
    isError.value = value
}

async function markAlertError() {
    const response = await setAlertStatusToCompleteService(props.alertId)

    if (response.hasErrors) return console.error(`Error updating alert status to complete: ${response.message}`)

    // * Refresh alerts in peding status
    await getTotalPendingAlerts()

    router.push('/')

    return sweetSuccess(response.data.message)
}
</script>