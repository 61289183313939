export const alertReportModel = (fields) => {
    const filesPath = process.env.VUE_APP_FILES_PATH

    return {
        associateInfo: {
            associateImage: fields?.associateInfo.associateImage ? filesPath + fields.associateInfo.associateImage : null,
            associateName: fields?.associateInfo.associateName ?? "",
            associateEmail: fields?.associateInfo.associateEmail ?? "",
            associateDob: fields?.associateInfo.associateDob ?? "",
            associateAddress: fields?.associateInfo.associateAddress ?? "",
            associatePhone: fields?.associateInfo.associatePhone ?? "",
            alertDate: fields?.associateInfo.alertDate ?? "",
            alertTime: fields?.associateInfo.alertTime ?? "",
            alertStatusId: fields?.associateInfo.alertStatusId ?? "",
            alertStatusName: fields?.associateInfo.alertStatusName ?? "",
        },
        evidences: fields?.evidences ?? [],
        locations: fields?.locations ?? [],
    }
}